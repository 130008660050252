import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const VideoSection = () => {
  const [showThumbnail, setShowThumbnail] = useState(true);

  const handleThumbnailClick = () => {
    setShowThumbnail(false);
  };

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  return (
    <Box
      className="videoSectionMainContainer"
      sx={{
        width: "100%",
        height: "746px",
        background: "#fff",
        backgroundImage: "url('/HomePage/videoBg.svg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
      ref={ref}
    >
      <Box
        className="videoAndHeadingContainer"
        display={"flex"}
        alignItems={"center"}
        gap={"150px"}
        mt={"150px"}
      >
        <motion.div
          initial={{ x: -500, opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: -500, opacity: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Box position={"relative"} className="videoMainBox">
            <Box className="videoEmptyBox"></Box>
            <Box
              className="videoBox"
              sx={{ position: "absolute", width: "484px" }}
            >
              {/* <img
                src="/HomePage/videoThumb.png"
                alt="Video Thumbnail"
                loading="lazy"
              /> */}
              {showThumbnail && (
                <Box
                  className="thumbnail"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    borderRadius: "20px",
                    backgroundImage: "url('/HomePage/videoThumb.png')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleThumbnailClick}
                >
                  <Box sx={{ width: "90px", height: "90px" }}>
                    {" "}
                    <img
                      style={{ cursor: "pointer" }}
                      src="/HomePage/videoIcon.png"
                      alt="Icon"
                      loading="lazy"
                    />
                  </Box>
                </Box>
              )}
              <iframe
                width="100%"
                style={{
                  borderRadius: "20px",
                  display: showThumbnail ? "none" : "block",
                }}
                height="100%"
                // src="https://drive.google.com/file/d/1-8eCgCnC8SWfiDefqEekBvkE8R6XrMsI/preview"
                src="https://drive.google.com/file/d/1QKnzt5cKbZRL_kiQn0p1UGYdVLVTP8ed/preview"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Instruction Video"
              ></iframe>
            </Box>
          </Box>
        </motion.div>

        <motion.div
          initial={{ x: 500, opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: 500, opacity: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Box className="videoHeading">
            <Typography fontSize={"50px"} className="howCbxText">
              How CBXTREE{" "}
            </Typography>
            <Typography fontSize={"50px"} className="works">
              Works?
            </Typography>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
};

export default VideoSection;
