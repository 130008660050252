import { React, useEffect, useMemo, useState } from "react";
import Link from "../Link.js";
import SocialLink from "../SocialLink";
import { useMediaQuery } from "../hook.js";
import { IoMdSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { IconButton, Tooltip } from "@mui/material";

const LinksPreview = (props) => {
  let { viewlinks, viewsetting } = props;
  const [page, setPage] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const isRowBased = useMediaQuery("(min-width: 500px)");

  const handleToggleSearch = () => setIsSearch(!isSearch);

  const handleClose = () => {
    setSearch("");
    setIsSearch(false);
  };

  useEffect(() => {
    setPage(viewsetting?.showLinksPerPage?.linksLimit);
  }, [viewsetting]);

  const DriveUrl = (logoUrl) => {
    let link = logoUrl;
    if (logoUrl?.indexOf("https://drive.google.com") > -1) {
      var id = logoUrl.match(/[-\w]{25,}/)[0];
      link = "https://lh3.google.com/u/0/d/" + id;
    }
    return link;
  };

  const lgBackground = (bg) => {
    if (bg && bg.includes("→")) {
      const colors = bg.split(" → ");
      return `linear-gradient(to right, ${colors[0]}, ${colors[1]})`;
    }
    return bg;
  };

  var bgImage = viewsetting?.backgroundImage;
  var bgLinear = viewsetting?.background;

  const backgroundStyle = {
    background:
      bgImage === "" || bgImage === undefined
        ? bgLinear !== ""
          ? lgBackground(bgLinear) || bgLinear
          : "#fff"
        : `url(${DriveUrl(bgImage)})`,
    minHeight: "100vh",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    top: "",
    bottom: "0",
    boxSizing: "border-box",
  };

  // Search filter
  const customStyles = {
    search: (isRowBased) => ({
      padding: "10px",
      marginInline: "20px",
      borderRadius: "8px",
      width: isRowBased ? "20%" : "60%",
      border: "none",
      cursor: "pointer",
      outline: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`, //1
    }),
  };

  const styleSearch = {
    search: (isRowBased) => ({
      textAlign: isRowBased ? "right" : "center",
    }),
  };

  const searchData = useMemo(
    () =>
      viewlinks?.filter((item) => {
        const searchLower = search.toLowerCase();
        return (
          searchLower === "" ||
          item.description.toLowerCase().includes(searchLower) ||
          item.link.toLowerCase().includes(searchLower) ||
          item.linkType.toLowerCase().includes(searchLower) ||
          item.instantLink.toLowerCase().includes(searchLower)
        );
      }),
    [viewlinks, search]
  );

  //Pagination constant
  const recordsPerPage = page || 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  var records = searchData?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(searchData?.length / recordsPerPage);

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div style={backgroundStyle} className="dashboardLinksPreview">
      <div className="d-flex justify-content-end p-3">
        <Tooltip title="Close the model">
          <IconButton
            data-bs-dismiss="modal"
            aria-label="Close"
            sx={{ color: `${viewsetting?.headersSettings?.fontColor}` }}
          >
            <IoClose />
          </IconButton>
        </Tooltip>
      </div>
      <div className="d-flex justify-content-center">
        {viewsetting && (
          <div className="m-2" style={{ textAlign: "center" }}>
            <div>
              {viewsetting.logo && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={DriveUrl(viewsetting.logo)}
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                    margin: "10px",
                    padding: "5px",
                    objectFit: "cover",
                  }}
                  alt="Logo"
                />
              )}
            </div>
            <div>
              <h2
                style={{
                  color: `${viewsetting?.headersSettings?.fontColor}`,
                  fontFamily: `${viewsetting?.headersSettings?.fontFamily}`,
                  fontSize: `${viewsetting?.headersSettings?.fontSize}`,
                  fontWeight: "550",
                }}
              >
                {viewsetting.header}
              </h2>
            </div>
            {viewsetting?.subheading && (
              <div>
                <h2
                  style={{
                    color: `${viewsetting?.subHeadingSettings?.fontColor}`,
                    fontFamily: `${viewsetting?.subHeadingSettings?.fontFamily}`,
                    fontSize: `${viewsetting?.subHeadingSettings?.fontSize}`,
                  }}
                >
                  {viewsetting?.subheading}
                </h2>
              </div>
            )}
          </div>
        )}
      </div>

      {viewsetting?.search && (
        <div style={styleSearch.search(isRowBased)} className="searchInputDiv">
          {isSearch && viewsetting?.search ? (
            <input
              className="searchInput"
              type="text"
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentPage(1);
              }}
              style={customStyles.search(isRowBased)}
              id="gsearch"
              name="gsearch"
              placeholder="Search"
            />
          ) : (
            <div> </div>
          )}

          {isSearch ? (
            <IconButton
              sx={{
                padding: "4px",
                mr: "20px",
                border: `1px solid ${
                  viewsetting?.paginationSetting?.paginationBtnBorder || "#fff"
                }} `,
              }}
              onClick={handleClose}
            >
              <IoClose
                style={{
                  color: `${
                    viewsetting?.paginationSetting?.paginationBtnBorder ||
                    "#fff"
                  }`,
                }}
              />
            </IconButton>
          ) : (
            <IconButton
              sx={{
                mr: "20px",
                padding: "5px",
                border: `1px solid ${
                  viewsetting?.paginationSetting?.paginationBtnBorder || "#fff"
                }}`,
              }}
              onClick={handleToggleSearch}
            >
              <IoMdSearch
                style={{
                  color: `${
                    viewsetting?.paginationSetting?.paginationBtnBorder ||
                    "#fff"
                  }`,
                }}
              />
            </IconButton>
          )}
        </div>
      )}
      <div>
        {records
          ?.slice()
          .sort((a, b) => a.order - b.order)
          .map(
            (link, i) =>
              link.isTrue && (
                <Link link={link} viewsetting={viewsetting} key={i} />
              )
          )}
      </div>

      <div className="d-flex justify-content-evenly my-3">
        {viewsetting?.footer &&
        viewsetting?.leftFooter.length > 0 &&
        viewsetting?.leftFooter[0]?.leftFooterName !== "" ? (
          <div>
            <a
              style={{
                border: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`, //1
                color: `${viewsetting?.leftFooterSetting?.color}`,
                fontFamily: `${viewsetting?.leftFooterSetting?.family}`,
                fontSize: `${viewsetting?.leftFooterSetting?.size}`,
                background: `${viewsetting?.leftFooterSetting?.background}`,
              }}
              type="button"
              className="btn btn-primary"
              href={viewsetting?.leftFooter[0]?.leftFooterURL}
              target="_blank"
              rel="noreferrer"
            >
              {viewsetting?.leftFooter[0]?.leftFooterName}
            </a>
          </div>
        ) : (
          <div>
            <a
              type="button"
              style={{ visibility: "hidden" }}
              className="btn btn-primary"
            >
              ..
            </a>
          </div>
        )}
        {searchData?.length > page ? (
          <div className="d-flex justify-content-evenly">
            <div className="mx-2">
              <button
                style={{
                  border: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`, //2
                  color: `${viewsetting?.paginationSetting?.color}`,
                  fontFamily: `${viewsetting?.paginationSetting?.family}`,
                  fontSize: `${viewsetting?.paginationSetting?.size}`,
                  background: `${viewsetting?.paginationSetting?.background}`,
                  borderRadius: "10px",
                }}
                type="button"
                className="btn btn-outline-primary"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </div>
            <div className="mx-2">
              <button
                style={{
                  border: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`, //3
                  color: `${viewsetting?.paginationSetting?.color}`,
                  fontFamily: `${viewsetting?.paginationSetting?.family}`,
                  fontSize: `${viewsetting?.paginationSetting?.size}`,
                  background: `${viewsetting?.paginationSetting?.background}`,
                  borderRadius: "10px",
                }}
                type="button"
                className="btn btn-outline-primary"
                onClick={nextPage}
                disabled={currentPage === npage}
              >
                Next
              </button>
            </div>
          </div>
        ) : null}
        {viewsetting?.footer &&
        viewsetting?.rightFooter.length > 0 &&
        viewsetting?.rightFooter[0]?.rightFooterName !== "" ? (
          <div>
            <a
              style={{
                border: `1px solid ${viewsetting?.paginationSetting?.paginationBtnBorder}`, //4
                color: `${viewsetting?.rightFooterSetting?.color}`,
                fontFamily: `${viewsetting?.rightFooterSetting?.family}`,
                fontSize: `${viewsetting?.rightFooterSetting?.size}`,
                background: `${viewsetting?.rightFooterSetting?.background}`,
              }}
              type="button"
              className="btn btn-primary"
              href={viewsetting?.leftFooter[0]?.leftFooterURL}
            >
              {viewsetting?.rightFooter[0]?.rightFooterName}
            </a>
          </div>
        ) : (
          <div>
            <a
              type="button"
              style={{ visibility: "hidden" }}
              className="btn btn-primary"
            >
              ..
            </a>
          </div>
        )}
      </div>

      {viewsetting?.companyName && (
        <div className="d-flex justify-content-center">
          <div className="companyTag">
            {/* <a
              href="https://ceoitbox.com/"
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}
            > */}
            <h3
              style={{
                padding: "4px",
                margin: "0px",
                color: `${viewsetting?.headersSettings?.fontColor}`, //5
                fontFamily: `${viewsetting?.headersSettings?.fontFamily}`, //6
                fontSize: "25px", //7
                fontWeight: "500", //8
              }}
            >
              {viewsetting?.companyName}
            </h3>
            {/* </a> */}
          </div>
        </div>
      )}

      {viewsetting?.socialLinks?.length > 0 && (
        <div className="d-flex justify-content-center">
          {viewsetting?.socialLinks?.map((socialLink, i) => {
            return (
              <SocialLink
                viewsetting={viewsetting}
                linktype={socialLink?.linkType}
                linkurl={socialLink?.linkUrl}
                key={i}
              />
            );
          })}
          {/* <SocialLink /> */}
        </div>
      )}
      {viewsetting && (
        <div className="d-flex justify-content-center">
          <h6
            style={{
              fontFamily: `${viewsetting?.descriptionSettings?.family}`,
              fontSize: `${viewsetting?.descriptionSettings?.size}`,
              color: `${
                viewsetting?.descriptionSettings?.descriptionColor ||
                viewsetting?.descriptionSettings?.color
              }`, //9
              textAlign: "center", //10
              paddingBottom: "15px",
            }}
          >
            {viewsetting.description}
          </h6>
        </div>
      )}
    </div>
  );
};

export default LinksPreview;
