import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import linkContext from "../context/links/linkContext";
import Linkitem from "./Linkitem";
import Addlink from "./Addlink";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import Themes from "./Themes/Themes";
import { Button, Tooltip } from "antd";
import Preview from "./Preview";
import { IconButton } from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { FaSave } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import PasswordModal from "./UserLinksDashboard/Modals/PasswordModal";
import Cookies from "js-cookie";
import { IoQrCodeOutline } from "react-icons/io5";
import QRCodeModal from "./QRCodeModal/QRCodeModal";
import { FaRegCopy } from "react-icons/fa6";
import { FaLinkSlash } from "react-icons/fa6";
import AddLinksBreakDownHeaderModel from "./UserLinksDashboard/Modals/AddLinksBreakDownHeaderModel";

const Links = (props) => {
  const ref = useRef(null);
  const refClose = useRef(null);
  const shareUserName = Cookies.get("cbxtree");
  const navigate = useNavigate();
  const context = useContext(linkContext);
  const {
    links,
    getLinks,
    editLink,
    getSettings,
    updateOrder,
    settings,
    previewUrl,
    handleAddUseId,
    handleUpdateQRCodeStatus,
  } = context;
  const [linkData, updateLinkData] = useState([]);
  const [mainSettings, setMainSettings] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userId, setUserid] = useState("");
  const [userDomain, setUserDomian] = useState("");
  const [viewUrl, setViewUrl] = useState(false);
  const [open, setOpen] = useState(false);
  const [openQRModal, setOpenQRModal] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [openAddHeaderModel, setOpenAddHeaderModel] = useState(false);

  const [link, setLink] = useState({
    id: "",
    edescription: "",
    elink: "",
    elinkType: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("token")) {
        await getLinks();
        await getSettings();
      } else {
        navigate("/login");
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const userName = useMemo(() => {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = previewUrl.match(regex);
    return match && match[1] ? match[1] : "";
  }, [previewUrl]);

  useEffect(() => {
    if (Array.isArray(settings)) {
      const data = settings?.filter((item) => item.userid === userName);
      setMainSettings(data[0]);
    } else {
      console.error("settings is not an array", settings);
    }
  }, [settings, userName]);

  useEffect(() => {
    if (Array.isArray(links)) {
      const newData = links?.filter((item) => item.userid === userName);
      setFilteredData(newData);
    }
  }, [links, userName]);

  useEffect(() => {
    if (Array.isArray(filteredData)) {
      var sortedLinks = filteredData?.slice().sort((a, b) => a.order - b.order);
      updateLinkData(sortedLinks);
    }
  }, [filteredData]);

  const updateLink = useCallback((currentLink) => {
    ref.current.click();
    setLink({
      id: currentLink._id,
      edescription: currentLink.description,
      elink: currentLink.link,
      elinkType: currentLink.linkType,
      einstantLink: currentLink.instantLink,
    });
  }, []);

  const handleClick = (e) => {
    editLink(
      link.id,
      link.edescription,
      link.elink,
      link.elinkType,
      link.einstantLink
    );
    refClose.current.click();
    props.showAlert("Updated Successfully", "success");
    // addLink(link);
  };

  const onChange = (e) => {
    setLink({ ...link, [e.target.name]: e.target.value });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(linkData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateOrder(items);
    updateLinkData(items);
  };

  useEffect(() => {
    if (previewUrl) {
      let location = previewUrl.split("/");
      const userid = location[3];
      setUserid(userid);
      location.pop();
      let userdomain = location.join("/").concat("/");
      setUserDomian(userdomain);
    }
  }, [previewUrl]);

  const onchangeUserId = (e) => {
    setUserid(e.target.value);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseQRModal = () => setOpenQRModal(false);

  const handleOpenQRCode = () => {
    setOpenQRModal(true);
    setIsCheck(true);
  };

  useEffect(() => {
    if (isCheck && mainSettings && !mainSettings?.isQRCodeGenrate) {
      handleUpdateQRCodeStatus(isCheck, userName).then((updatedSettings) => {
        setMainSettings(updatedSettings);
      });
    }
  }, [isCheck, mainSettings, userName]);

  return (
    <>
      <div>
        <div className="previewBtnDiv">
          <button
            className="btn btn-outline-primary shadow-none"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#previewModel"
          >
            <i className="fa-solid fa-eye"></i>
            <span style={{ marginLeft: "5px", fontSize: "14px" }}>PREVIEW</span>
          </button>
        </div>

        <div className="linkAddressDiv">
          <div className="input-group " style={{ marginTop: "10px" }}>
            <div className="form-control d-flex align-items-center linkAddressInput">
              <span>{userDomain}</span>
              <input
                type="text"
                aria-label="Add Header"
                aria-describedby="button-addon2"
                value={userId}
                onChange={onchangeUserId}
                style={{
                  // c9cacb
                  borderRadius: "4px",
                  background: viewUrl ? "#F8F8F8" : "#fff",
                  border: viewUrl ? "1px solid #198754" : "none",
                  outline: "none",
                  width: "100%",
                  paddingInline: viewUrl ? "5px" : "0px",
                }}
                disabled={!viewUrl ? "disabled" : ""}
              />
            </div>

            {/* Add password button*/}
            <Tooltip title="Secure your Cbxtree : Click to set or update your password for added protection.">
              <IconButton
                onClick={handleOpen}
                sx={{
                  position: "relative",
                  zIndex: 100,
                  fontWeight: 500,
                  border: "1px solid #4F8B33",
                  color: "#4F8B33",
                  borderColor: "#4F8B33",
                  "&:hover": { background: "#4F8B33", color: "#fff" },
                }}
                className="editAddressBtn"
              >
                <RiLockPasswordFill size={18} />
              </IconButton>
            </Tooltip>

            {!viewUrl ? (
              <Tooltip
                title={
                  shareUserName
                    ? "You do not have permission to edit alias"
                    : "Edit your alias."
                }
              >
                <IconButton
                  className="editAddressBtn"
                  sx={{
                    position: "relative",
                    zIndex: 100,
                    fontWeight: 500,
                    cursor: shareUserName ? "default" : "pointer",
                    color: shareUserName ? "#a9a9a9" : "#4F8B33",
                    background: shareUserName ? "#e8e6e6" : "",
                    borderColor: "#4F8B33",
                    "&:hover": {
                      background: shareUserName ? "#e8e6e6" : "#4F8B33",
                      color: shareUserName ? "#a9a9a9" : "#fff",
                    },
                    border: "1px solid #4F8B33",
                  }}
                  variant="outlined"
                  onClick={() => {
                    !shareUserName && setViewUrl(!viewUrl);
                  }}
                >
                  <CiEdit size={18} />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                className="editAddressBtn"
                sx={{
                  border: "1px solid #4F8B33",
                  position: "relative",
                  zIndex: 100,
                  fontWeight: 500,
                  color: "#4F8B33",
                  borderColor: "#4F8B33",
                  "&:hover": { background: "#4F8B33", color: "#fff" },
                }}
                variant="outlined"
                onClick={() => {
                  setViewUrl(!viewUrl);
                  handleAddUseId(userId, props);
                }}
              >
                <FaSave size={18} />
              </IconButton>
            )}

            {/* QR Code */}
            <Tooltip
              title={
                mainSettings && mainSettings.isQRCodeGenrate
                  ? "View Your QR Code"
                  : "Generate QR Code"
              }
            >
              <IconButton
                onClick={handleOpenQRCode}
                className="editAddressBtn"
                sx={{
                  border: "1px solid #4F8B33",
                  position: "relative",
                  zIndex: 100,
                  fontWeight: 500,
                  color: "#4F8B33",
                  borderColor: "#4F8B33",
                  "&:hover": { background: "#4F8B33", color: "#fff" },
                }}
                variant="outlined"
              >
                <IoQrCodeOutline size={18} />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Copy Link Address"}>
              <IconButton
                className="editAddressBtn"
                sx={{
                  fontWeight: 500,
                  color: "#4F8B33",
                  position: "relative",
                  zIndex: 100,
                  borderColor: "#4F8B33",
                  border: "1px solid #4F8B33",
                  "&:hover": { background: "#4F8B33", color: "#fff" },
                }}
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(previewUrl);
                  props.showAlert("Copied", "success");
                }}
              >
                <FaRegCopy size={18} />
              </IconButton>
            </Tooltip>

            {/* small screen */}
            <div style={{ display: "none" }} className="editIconAndCopyIconDiv">
              <div>
                {" "}
                <Tooltip title="Secure your Cbxtree : Click to set or update your password for added protection.">
                  <IconButton
                    onClick={handleOpen}
                    sx={{
                      fontWeight: 500,
                      border: "1px solid #4F8B33",
                      p: "8px",
                      color: "#4F8B33",
                      borderColor: "#4F8B33",
                      "&:hover": { background: "#4F8B33", color: "#fff" },
                    }}
                  >
                    <RiLockPasswordFill size={18} />
                  </IconButton>
                </Tooltip>
              </div>

              {!viewUrl ? (
                <Tooltip
                  title={
                    shareUserName
                      ? "You do not have permission to edit alias"
                      : "Edit your alias."
                  }
                >
                  <IconButton
                    sx={{
                      fontWeight: 500,
                      cursor: shareUserName ? "default" : "pointer",
                      color: shareUserName ? "#a9a9a9" : "#4F8B33",
                      background: shareUserName ? "#e8e6e6" : "",
                      borderColor: "#4F8B33",
                      border: "1px solid #4F8B33",
                      "&:hover": {
                        background: shareUserName ? "#e8e6e6" : "#4F8B33",
                        color: shareUserName ? "#a9a9a9" : "#fff",
                      },
                    }}
                    // startIcon={<CiEdit />}
                    variant="outlined"
                    onClick={() => {
                      !shareUserName && setViewUrl(!viewUrl);
                    }}
                  >
                    <CiEdit size={18} />
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton
                  sx={{
                    fontWeight: 500,
                    color: "#4F8B33",
                    borderColor: "#4F8B33",
                    border: "1px solid #4F8B33",
                    "&:hover": { background: "#4F8B33", color: "#fff" },
                  }}
                  variant="outlined"
                  onClick={() => {
                    setViewUrl(!viewUrl);
                    handleAddUseId(userId, props);
                  }}
                >
                  <FaSave size={18} />
                </IconButton>
              )}
              <Tooltip
                title={
                  mainSettings && mainSettings.isQRCodeGenrate
                    ? "View Your QR Code"
                    : "Generate QR Code"
                }
              >
                <IconButton
                  onClick={handleOpenQRCode}
                  sx={{
                    border: "1px solid #4F8B33",
                    position: "relative",
                    zIndex: 100,
                    fontWeight: 500,
                    color: "#4F8B33",
                    borderColor: "#4F8B33",
                    "&:hover": { background: "#4F8B33", color: "#fff" },
                  }}
                  variant="outlined"
                >
                  <IoQrCodeOutline size={18} />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Copy Link Address"}>
                <IconButton
                  sx={{
                    fontWeight: 500,
                    color: "#4F8B33",
                    borderColor: "#4F8B33",
                    border: "1px solid #4F8B33",
                    "&:hover": { background: "#4F8B33", color: "#fff" },
                  }}
                  variant="outlined"
                  onClick={() => {
                    navigator.clipboard.writeText(previewUrl);
                    props.showAlert("Copied", "success");
                  }}
                >
                  <FaRegCopy size={18} />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          {/* <!-- Modal --> */}
          <div
            className="modal fade"
            id="previewModel"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-lg">
              <div
                className="modal-content"
                style={{
                  marginTop: "65px",
                  borderRadius: linkData?.length > 0 ? "" : "20px",
                }}
              >
                {linkData?.length > 0 ? (
                  <Preview viewsetting={mainSettings} viewlinks={linkData} />
                ) : (
                  <div
                    style={{
                      margin: "10px 10px 5px 10px",
                      padding: "10px",
                      textAlign: "center",
                      color: "#777e90",
                      fontWeight: 500,
                    }}
                  >
                    <h4 style={{ fontSize: "20px" }}>
                      Please add links to get preview
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Add link component */}
        <Addlink showAlert={props.showAlert} settings={settings} />

        {/* Button trigger modal  */}
        <button
          type="button"
          ref={ref}
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Launch demo modal
        </button>

        {/* Modal  */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ borderRadius: "10px" }}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {link.elinkType == "links header"
                    ? "Edit Header Name"
                    : " Edit Link"}
                </h5>
                <button
                  style={{ fontSize: "14px" }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="edescription"
                      name="edescription"
                      value={link.edescription}
                      aria-describedby="emailHelp"
                      onChange={onChange}
                      placeholder={
                        link.elinkType === "links header"
                          ? "Enter Header Name"
                          : "Enter Description"
                      }
                    />
                  </div>
                  {link.elinkType !== "links header" && (
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="elink"
                        name="elink"
                        value={link.elink}
                        onChange={onChange}
                        placeholder="Enter Redirect Link"
                      />
                    </div>
                  )}
                  {link.elinkType !== "links header" && (
                    <div className="mb-3">
                      <select
                        className="form-select"
                        id="elinkType"
                        name="elinkType"
                        value={link.elinkType}
                        aria-label="Default select example"
                        onChange={onChange}
                        placeholder="Select"
                      >
                        {/* <option value="Normal Link" selected disabled>Select Link Type</option> */}
                        <option value="Normal Link">Normal Link</option>
                        <option value="Add Description">Add Description</option>
                        <option value="Normal Image Link">
                          Normal Image Link
                        </option>
                        <option value="Drive Image Link">
                          Drive Image Link
                        </option>
                        <option value="Drive Video">Drive Video</option>
                        <option value="Youtube Video">Youtube Video</option>
                      </select>
                    </div>
                  )}

                  {link.elinkType !== "links header" &&
                    link.elinkType !== "Add Description" &&
                    link.elinkType !== "Normal Link" && (
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="einstantLink"
                          name="einstantLink"
                          value={link.einstantLink}
                          onChange={onChange}
                          placeholder="Enter Display Link"
                        />
                      </div>
                    )}
                  {link.elinkType === "Add Description" && (
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        type="text"
                        id="einstantLink"
                        name="einstantLink"
                        value={link.einstantLink}
                        onChange={onChange}
                        placeholder="Enter description"
                        maxLength="600"
                      />
                      {link?.einstantLink.length > 0 && (
                        <div
                          className="mt-2"
                          style={{ color: "red", fontSize: "14px" }}
                        >
                          Maximum length of {link.einstantLink.length}/600
                          characters exceeded!
                        </div>
                      )}
                    </div>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  style={{
                    color: "#4F8B33",
                    border: "1px solid #4F8B33",
                    fontSize: "14px",
                    background: "#fff",
                    fontWeight: 500,
                  }}
                  ref={refClose}
                  type="button"
                  className="btn btn-secondary modalCloseBtn"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {link.elinkType === "links header" ? (
                  <button
                    style={{
                      background: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                    }}
                    type="button"
                    className="btn btn-primary modalSaveBtn"
                    onClick={handleClick}
                  >
                    {link.elinkType === "links header"
                      ? "Update Header"
                      : "Update Link"}
                  </button>
                ) : (
                  <button
                    style={{
                      background: "#4F8B33",
                      border: "1px solid #4F8B33",
                      fontSize: "14px",
                    }}
                    disabled={
                      link.edescription.length === 0 ||
                      link.elink.length === 0 ||
                      link.elinkType.length === 0
                    }
                    type="button"
                    className="btn btn-primary modalSaveBtn"
                    onClick={handleClick}
                  >
                    Update Link
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Drag and drop context  */}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
              padding: "10px 0px 0px 0px",
            }}
          >
            <h3 style={{ fontWeight: 600, margin: 0 }}>
              <span style={{ color: "#4D8733" }}>List of</span>
              <span style={{ color: "#B4D33B" }}> Links</span>
            </h3>
            <div
              style={{
                position: "relative",
                zIndex: 100,
              }}
            >
              <Button
                type="primary"
                icon={<FaLinkSlash />}
                onClick={() => setOpenAddHeaderModel(true)}
              >
                Add Header
              </Button>
            </div>
          </div>
          <Droppable droppableId="links">
            {(provided) => (
              <div
                className="links"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {linkData?.map((link, index) => {
                  return (
                    <Draggable
                      key={link._id}
                      draggableId={link._id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Linkitem
                            key={link._id}
                            updateLink={updateLink}
                            link={link}
                            index={index}
                            showAlert={props.showAlert}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* Theme component */}
        <div>
          <Themes />
        </div>

        {/* Show QR Code Modal */}
        <QRCodeModal
          open={openQRModal}
          handleClose={handleCloseQRModal}
          qrUrl={previewUrl}
          userName={userName}
        />

        {/* Add pasword modal */}
        <PasswordModal
          handleClose={handleClose}
          open={open}
          userName={userName}
        />

        {/* Header model */}
        <AddLinksBreakDownHeaderModel
          handleClose={() => setOpenAddHeaderModel(false)}
          open={openAddHeaderModel}
          userName={userName}
        />
      </div>
    </>
  );
};

export default Links;
