import { Modal, Button, Typography, Box, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import linkContext from "../../../context/links/linkContext";
import { message } from "antd";

// Modal style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "10px 20px",
};

const AddLinksBreakDownHeaderModel = ({ open, handleClose, userName }) => {
  const context = useContext(linkContext);
  const { addLink, isAddLinksLoading } = context;

  const [links, setLinks] = useState({
    description: "",
    link: "https://cbxtree.in/",
    instantLink: "",
  });

  const handleChange = (e) => {
    setLinks((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = async () => {
    if (!links?.description?.trim()) {
      message.error("Header cannot be empty");
      return;
    }

    const linkData = {
      ...links,
      linkType: "links header",
      userid: userName,
    };

    await addLink(linkData);
    setLinks({ description: "", link: "", instantLink: "" });
    handleClose();
    message.success("Header Added Successfully");
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style} className="modalContainer">
        <Typography
          style={{ fontWeight: "500", color: "#333" }}
          variant="h6"
          component="h2"
        >
          Add Header
        </Typography>
        <Box component="form" sx={{ mt: 0 }}>
          <TextField
            sx={{ position: "relative" }}
            type="text"
            margin="normal"
            required
            fullWidth
            name="description"
            label="Header Name"
            value={links.description}
            onChange={handleChange}
            InputLabelProps={{
              style: {
                textTransform: "none",
              },
            }}
          />
          <Box sx={{ float: "inline-end", mt: 2 }}>
            <Button onClick={handleClose}>Close</Button>
            <Button
              type="button"
              disabled={!links.description.trim()}
              variant="contained"
              onClick={handleSave}
              sx={{ ml: 1 }}
            >
              {isAddLinksLoading ? "Saving..." : "Save"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddLinksBreakDownHeaderModel;
