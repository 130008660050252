import { React, useContext, useEffect } from "react";
import AllRoutes from "./components/Routes/AllRoutes";
import useDynamicFaviconAndTitle from "./components/DynamicFaviconAndTitle/useDynamicFaviconAndTitle";
import { IconButton, Box } from "@mui/material";
import { FaUserCircle } from "react-icons/fa";
import linkContext from "./context/links/linkContext";
import { Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MdAdminPanelSettings } from "react-icons/md";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(linkContext);
  let { user, userDetails, shouldHideIcons } = context;

  useDynamicFaviconAndTitle();

  useEffect(() => {
    const path = window.location.pathname;
    if (path && path !== "/") {
      navigate(path, { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    userDetails();
  }, []);

  const handleClickSecurityIcon = () => {
    navigate("/users");
  };

  const pathArr = ["/", "/login", "/signup", "/sendotp", "/users"];
  const shouldHideIcons1 = pathArr.includes(location.pathname);

  return (
    <Box sx={{ position: "relative" }}>
      <AllRoutes />

      {!shouldHideIcons1 && !shouldHideIcons && (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            position: "fixed",
            maxHeight: "100vh",
            bottom: "15px",
            right: "10px",
            zIndex: 100,
          }}
        >
          <Box>
            {" "}
            <Tooltip title={<span>Email : {user && user.email}</span>}>
              <IconButton
                sx={{
                  color: "#fff",
                  background:
                    "linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)",
                  "&:hover": {
                    background:
                      "linear-gradient(110deg, #f52bff 3.48%, #00bce0 81.52%)",
                  },
                }}
              >
                <FaUserCircle />
              </IconButton>
            </Tooltip>
          </Box>

          {(user && user?.role === "Admin") ||
            (user?.role === "admin" && (
              <Box>
                <Tooltip title="Admin dashboard">
                  <IconButton
                    onClick={handleClickSecurityIcon}
                    sx={{
                      color: "#fff",
                      // background: "#00bce0",
                      background:
                        "linear-gradient(110deg, #00bce0 3.48%, #f52bff 81.52%)",
                      "&:hover": {
                        // background: "#09b1c3",
                        background:
                          "linear-gradient(110deg, #f52bff 3.48%, #00bce0 81.52%)",
                      },
                    }}
                  >
                    <MdAdminPanelSettings />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
}

export default App;
