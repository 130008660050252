import React from "react";
import { Modal, Box, Typography, IconButton, Button } from "@mui/material";
import {
  FaXTwitter,
  FaWhatsapp,
  FaLinkedinIn,
  FaRegCopy,
} from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { message } from "antd";
import { FaFacebook } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";

const socialPlatforms = [
  {
    name: "Copy CBXTREE",
    icon: <FaRegCopy />,
    color: "#000",
    background: "#f0f0f0",
  },

  {
    name: "WhatsApp",
    icon: <FaWhatsapp />,
    color: "#fff",
    background: "#25D366",
    link: "https://api.whatsapp.com/send?text=",
  },
  {
    name: "Facebook",
    icon: <FaFacebook />,
    color: "#fff",
    background: "#1877F2",
    link: "https://facebook.com/sharer/sharer.php?u=",
  },

  {
    name: "LinkedIn",
    icon: <FaLinkedinIn />,
    color: "#fff",
    background: "#0077b5",
    link: "https://www.linkedin.com/sharing/share-offsite/?url=",
  },
  {
    name: "Email",
    icon: <MdOutlineMail />,
    color: "#333",
    background: "#E0E0E0",
    link: `mailto:?subject=Check%20this%20out!&body=Here's%20something%20interesting:%20`,
  },
  {
    name: "Twitter",
    icon: <FaXTwitter />,
    color: "#fff",
    background: "#000",
    link: "https://twitter.com/share?url=",
  },
];

const ShareModal = ({
  open,
  onClose,
  data,
  backgroundStyle,
  // description = "Get your own free CBXTREE. The only link in bio trusted by 50M+ people.",
}) => {
  const DriveUrl = (logoUrl) => {
    let link = logoUrl;
    if (logoUrl?.indexOf("drive.google.com") > -1) {
      var id = logoUrl.match(/[-\w]{25,}/)[0];
      link = "https://lh3.google.com/u/0/d/" + id;
    }
    return link;
  };

  const convertGoogleDriveLinkToImage = (logoUrl) => {
    let link = logoUrl;
    if (logoUrl?.indexOf("drive.google.com") > -1) {
      var id = logoUrl.match(/[-\w]{25,}/)[0];
      link = `https://drive.google.com/thumbnail?id=${id}&sz=w1000`;
    }
    return link;
  };

  const handleShare = (platform) => {
    if (platform.name === "Copy CBXTREE") {
      navigator.clipboard.writeText(`https://cbxtree.in/${data?.userid}`);
      message.success("CBXTREE copied to clipboard!");
    } else {
      window.open(
        `${platform.link}${encodeURIComponent(
          `https://cbxtree.in/${data?.userid}`
        )}`,
        "_blank"
      );
    }
  };

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            width: { xs: "90%", sm: 480, md: 480, lg: 480 },
            bgcolor: "white",
            borderRadius: 5,
            pt: 3,
            pb: 4,
            pl: 4,
            pr: 4,
            mx: "auto",
            boxShadow: 3,
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <IoClose size={24} />
          </IconButton>

          {/* Heading Section */}
          <Box
            sx={{
              width: "100%",
              mb: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: "20px" }}>
              Share CBXTREE
            </Typography>
          </Box>

          <Box
            sx={{
              mb: 4,
              borderRadius: 5,
              maxHeight: "300px",
              p: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "20px",
              ...backgroundStyle,
              minHeight: "0px",
              height: "auto",
            }}
          >
            <Box>
              {data?.logo &&
                (data?.logo.includes("drive.google.com") ? (
                  <img
                    src={convertGoogleDriveLinkToImage(data?.logo)}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      padding: "5px",
                      objectFit: "cover",
                    }}
                    alt="Logo"
                  />
                ) : (
                  <img
                    src={DriveUrl(data?.logo)}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      padding: "5px",
                      objectFit: "cover",
                    }}
                    alt="logo"
                  />
                ))}
            </Box>

            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src="/cbxFavIcon.svg"
                  alt="Profile"
                />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    color: data?.headersSettings?.fontColor || "#000",
                  }}
                >
                  /{data?.userid || ""}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Social Icons (Dynamic) */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 3,
              // pb: 4,
              // mb: 2,
              // borderBottom: "1px solid #ccc",
            }}
          >
            {socialPlatforms?.map((platform, index) => (
              <Box>
                <IconButton
                  key={index}
                  sx={{
                    color: platform.color,
                    background: platform.background,
                    transition: "transform 0.2s ease-in-out",
                    "&:hover": {
                      bgcolor: platform.background,
                      color: platform.color,
                      transform: "scale(1.1)",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  onClick={() => handleShare(platform)}
                >
                  {platform.icon}
                </IconButton>
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: "12px",
                    fontWeight: 500,
                    maxWidth: "50px",
                  }}
                >
                  {platform.name}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Title & Description */}
          {/* <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ textAlign: "start" }}
          >
            Join {data?.userid} Community
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            mb={2}
            sx={{ textAlign: "start" }}
          >
            {description}
          </Typography> */}

          {/* Buttons */}
          {/* <Box
            sx={{ display: "flex", gap: 4, justifyContent: "center", mt: 2 }}
          >
            <Button
              variant="contained"
              component={Link}
              to="/signup"
              sx={{
                bgcolor: "black",
                color: "white",
                borderRadius: "50px",
                textTransform: "none",
                padding: "8px 10px",
                width: "100%",
                fontSize: "16px",
                "&:hover": { bgcolor: "black", color: "#fff" },
              }}
            >
              Sign up free
            </Button>

            <Button
              component={Link}
              to="/"
              variant="outlined"
              sx={{
                borderRadius: "50px",
                textTransform: "none",
                padding: "8px 10px",
                width: "100%",
                fontSize: "16px",
              }}
            >
              Find out more
            </Button>
          </Box> */}
        </Box>
      </Modal>
    </div>
  );
};

export default ShareModal;
