import { React, useContext, useState, useRef, useEffect, useMemo } from "react";
import linkContext from "../context/links/linkContext";
import Table from "./Table";
import { Switch, Tooltip, DatePicker } from "antd";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import { GoInfo } from "react-icons/go";

const Linkitem = (props) => {
  const context = useContext(linkContext);
  const {
    deleteLink,
    addAnimation,
    addEndDate,
    onChangethumbnail,
    clicks,
    links,
    updatelinkED,
    fetchclicks,
    updateLinkSecureStatus,
  } = context;
  const { index, link, updateLink } = props;
  const refCloseThumbnail = useRef(null);
  const refCloseDeleteModal = useRef(null);
  const [filterClick, setFilterClick] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchclicks();
  }, []);

  const filterClicks = useMemo(
    () => clicks?.filter((click) => click.linkID === link._id),
    [clicks, link._id]
  );

  const showTable = (id) => {
    setFilterClick(filterClicks);
  };

  var searchData = filterClick?.filter((item) => {
    return search.toLowerCase() === ""
      ? item
      : item.city?.toLowerCase().includes(search) ||
          item.country?.toLowerCase().includes(search);
  });

  const isValidThumbnailLink = (link) => {
    if (link === undefined || link === null) {
      return false;
    }

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValid = urlRegex.test(link) || link === "";
    return isValid;
  };

  const [thumbnail, setThumbnail] = useState("");
  const uploadThumbnail = (id) => {
    if (!isValidThumbnailLink(thumbnail)) {
      props.showAlert("Invalid thumbnail link", "danger");
      return;
    }

    onChangethumbnail(id, thumbnail);
    props.showAlert("Update sucessfully", "success");

    if (refCloseThumbnail.current) {
      refCloseThumbnail.current.click();
    }
  };

  const handleDeleteLinks = (id) => {
    deleteLink(id);
    props.showAlert("Deleted sucessfully", "success");

    if (refCloseDeleteModal.current) {
      refCloseDeleteModal.current.click();
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    setThumbnail(value);
  };

  const onChangeSwitch = (_id) => updatelinkED(_id);
  const handleChangeLinkSecureSatus = (_id) => updateLinkSecureStatus(_id);

  useEffect(() => {
    const matchedLink = links?.find((l) => l._id === link._id);
    if (matchedLink) {
      setThumbnail(matchedLink.thumbnail);
    }
  }, [links, link._id]);

  // const endDateMoment = link.endDate ? dayjs(link.endDate) : null;
  const endDateMoment = link.endDate
    ? dayjs(link.endDate, "DD/MM/YYYY HH:mm")
    : null;

  return (
    <>
      <div
        className="card my-3 shadow-sm bg-white"
        style={{
          borderRadius: "20px",
          border: "none",
        }}
      >
        <div className="card-body">
          <span className="d-flex justify-content-center">
            <Tooltip title={"Drag and Drop to Rearrange Your Links"}>
              <img
                style={{
                  width: "25px",
                  height: "25px",
                  transform: "rotate(90deg)",
                }}
                src="./draggable-dots.ico"
                alt=""
              />
            </Tooltip>
          </span>
          <div className="d-flex justify-content-between">
            <div
              className="headingContainer"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "80%",
              }}
            >
              <h5
                className="card-title linkHeading"
                style={{ marginBottom: "4px" }}
              >
                {link.description}
              </h5>
              {link.linkType !== "links header" && (
                <p
                  className="card-text linkDescription"
                  style={{ color: "#777E90" }}
                >
                  {link.link}
                </p>
              )}
            </div>
            {link.linkType !== "links header" && (
              <div
                className="my-auto"
                style={{
                  position: "relative",
                  zIndex: 100,
                }}
              >
                <>
                  <Tooltip
                    title={link.isTrue ? "Link is visible" : "Link is hidden"}
                  >
                    <Switch
                      className="switch-big"
                      defaultChecked={link.isTrue}
                      onChange={() => onChangeSwitch(link._id)}
                    />
                    <Switch
                      style={{ display: "none" }}
                      size="small"
                      className="switch-small"
                      defaultChecked={link.isTrue}
                      onChange={() => onChangeSwitch(link._id)}
                    />
                  </Tooltip>
                  <Tooltip title="Toggle the switch to change the link visibility">
                    <GoInfo
                      color="#0d6efd"
                      style={{
                        marginLeft: "4px",
                        marginBottom: "5px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </>
              </div>
            )}

            {link.linkType === "links header" && (
              <div>
                <button
                  style={{
                    marginLeft: "5px",
                    position: "relative",
                    zIndex: 100,
                  }}
                  type="button"
                  class="btn btn-light"
                  onClick={() => {
                    updateLink(link);
                  }}
                >
                  {" "}
                  <Tooltip title={"Edit"}>
                    <i
                      className="fa-solid fa-pen-to-square"
                      style={{ color: "#4F8B33" }}
                    ></i>
                  </Tooltip>
                </button>

                <button
                  style={{
                    padding: "5px",
                    marginLeft: "5px",
                    position: "relative",
                    zIndex: 100,
                  }}
                  type="button"
                  class="btn btn-light"
                  className="btn btn-none shadow-none btn-light"
                  id="file"
                  data-bs-toggle="modal"
                  data-bs-target={"#delete" + index}
                >
                  <Tooltip title={"Delete"}>
                    <i
                      style={{ color: "#4F8B33" }}
                      className="fa-solid fa-trash-can mx-2"
                    ></i>
                  </Tooltip>
                </button>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between linksSettingDiv">
            {link.linkType !== "links header" && (
              <div
                className=""
                style={{
                  display: "Flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <DatePicker
                  // ref={ref}
                  format="DD/MM/YYYY HH:mm" // Use 'HH' for 24-hour format
                  onChange={(date, dateString) =>
                    addEndDate(link._id, dateString)
                  }
                  showTime={{
                    defaultValue: dayjs().hour(0).minute(0),
                    format: "HH:mm",
                  }} // Set showTime to 24-hour format
                  placeholder="Select End Date"
                  value={endDateMoment || undefined} // Provide undefined when endDateMoment is null
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  } // Set a range of valid dates
                />
                {/* Animation */}
                <div>
                  <label>
                    <select
                      style={{ color: "#808080" }}
                      className="form-select form-select-sm shadow-none"
                      aria-label=".form-select-sm example"
                      value={link.animation}
                      onChange={(e) => {
                        addAnimation(e, link._id);
                      }}
                    >
                      <option selected>Animation</option>
                      <option value="Bouncing">Bouncing</option>
                      <option value="Expansion">Expansion</option>
                      <option value="Fade">Fade</option>
                      <option value="Movesideways">Move sideways</option>
                      <option value="Colourhighlighter">
                        Colour highlighter
                      </option>
                    </select>
                  </label>
                </div>
              </div>
            )}

            <div className="linksSettingsIocns">
              {link.linkType !== "links header" && (
                <>
                  <Tooltip
                    title={
                      link.isSecure
                        ? "This link is secure"
                        : "This link is not secure"
                    }
                  >
                    <Switch
                      className="switch-big"
                      defaultChecked={link.isSecure}
                      onChange={() => handleChangeLinkSecureSatus(link._id)}
                    />
                    <Switch
                      style={{ display: "none" }}
                      size="small"
                      className="switch-small"
                      defaultChecked={link.isSecure}
                      onChange={() => handleChangeLinkSecureSatus(link._id)}
                    />
                  </Tooltip>
                  <Tooltip title="Toggle the switch to update the link security status">
                    <GoInfo
                      style={{
                        color: link.isSecure ? "#0d6efd" : "#4f8b33",
                        marginLeft: "4px",
                        marginBottom: "5px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </>
              )}
              {link.linkType !== "links header" && (
                <Tooltip title={"Analytics"}>
                  <label className="m-1 p-1">
                    <button
                      type="button"
                      className="btn btn-primary py-0 shadow-none"
                      onClick={() => showTable(link._id)}
                      style={{
                        cursor: "pointer",
                        background: "#4F8B33",
                        border: "1px solid #4F8B33",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target={"#clickTable" + index}
                    >
                      <i className="fa-solid fa-location-dot"></i>{" "}
                      {filterClicks?.length}
                    </button>
                  </label>{" "}
                </Tooltip>
              )}
              {link.linkType !== "links header" && (
                <Tooltip title={"Thumbnail"}>
                  <label>
                    <button
                      style={{ color: "#4F8B33" }}
                      type="button"
                      // class="btn btn-light"
                      className="btn btn-none shadow-none btn-light"
                      id="file"
                      data-bs-toggle="modal"
                      data-bs-target={"#thumbnail" + index}
                    >
                      <i htmlFor="file" className="fa-regular fa-image "></i>
                    </button>
                  </label>
                </Tooltip>
              )}
              {link.linkType !== "links header" && (
                <button
                  style={{
                    marginLeft: "5px",
                    position: "relative",
                    zIndex: 100,
                  }}
                  type="button"
                  class="btn btn-light"
                  onClick={() => {
                    updateLink(link);
                  }}
                >
                  {" "}
                  <Tooltip title={"Edit"}>
                    <i
                      className="fa-solid fa-pen-to-square"
                      style={{ color: "#4F8B33" }}
                    ></i>
                  </Tooltip>
                </button>
              )}

              {link.linkType !== "links header" && (
                <button
                  style={{
                    padding: "5px",
                    marginLeft: "5px",
                    position: "relative",
                    zIndex: 100,
                  }}
                  type="button"
                  class="btn btn-light"
                  className="btn btn-none shadow-none btn-light"
                  id="file"
                  data-bs-toggle="modal"
                  data-bs-target={"#delete" + index}
                >
                  <Tooltip title={"Delete"}>
                    <i
                      style={{ color: "#4F8B33" }}
                      className="fa-solid fa-trash-can mx-2"
                    ></i>
                  </Tooltip>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* The Modal for thumbnail upload */}
      <div
        className="modal"
        id={"thumbnail" + index}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content" style={{ borderRadius: "10px" }}>
            {/* Modal Header  */}
            <div className="modal-header">
              <h5 className="modal-title">
                {" "}
                {thumbnail ? "Edit Thumbnail" : "Upload Thumbnail"}{" "}
              </h5>
              <button
                style={{ fontSize: "14px" }}
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {/* Modal body */}
            <div className="modal-body" style={{ fontSize: "16px" }}>
              Enter thumbnail URL Link
              <input
                type="text"
                className="form-control"
                id="thumbnailInput"
                placeholder="Enter url"
                value={thumbnail}
                onChange={onChange}
              />
            </div>

            {/* Modal footer  */}
            <div className="modal-footer">
              <button
                style={{
                  color: "#4F8B33",
                  border: "1px solid #4F8B33",
                  fontSize: "14px",
                  background: "#fff",
                  fontWeight: 500,
                }}
                type="button"
                ref={refCloseThumbnail}
                className="btn btn-danger shadow-none modalCloseBtn"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                style={{
                  background: "#4F8B33",
                  border: "1px solid #4F8B33",
                  fontSize: "14px",
                }}
                type="button"
                className="btn btn-primary shadow-none modalSaveBtn"
                onClick={() => uploadThumbnail(link._id)}
              >
                Update Link
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* The Modal for clicks table  */}
      <div
        className="modal"
        id={"clickTable" + index}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div
            className="modal-content"
            style={{ borderRadius: "10px", marginTop: "55px" }}
          >
            {/* Modal Header  */}
            <div className="modal-header">
              <h4 className="modal-title">Analytics Table</h4>
              <div
                className="d-flex justify-content-end"
                style={{ alignItems: "center", gap: "20px" }}
              >
                <div>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    onChange={(e) => setSearch(e.target.value)}
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder="Search"
                  />
                </div>
                <div className="">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>
              </div>
            </div>

            {/* Modal body */}
            <div className="modal-body">
              {/* <Table data = {filterClick}/> */}
              {typeof filterClick !== "undefined" && (
                <Table data={searchData} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* The Modal for delete link */}
      <div
        className="modal"
        id={"delete" + index}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content" style={{ borderRadius: "10px" }}>
            {/* Modal Header  */}
            <div className="modal-header">
              <h5 className="modal-title" style={{ fontSize: "20px" }}>
                Are you sure you want to delete this link?
              </h5>
              <button
                style={{ fontSize: "12px", marginBottom: "12px" }}
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {/* Modal footer  */}
            <div className="modal-footer">
              <button
                style={{
                  color: "#4F8B33",
                  border: "1px solid #4F8B33",
                  fontSize: "14px",
                  background: "#fff",
                  fontWeight: 500,
                }}
                type="button"
                ref={refCloseDeleteModal}
                className="btn btn-danger shadow-none modalCloseBtn"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                style={{
                  background: "#4F8B33",
                  border: "1px solid #4F8B33",
                  fontSize: "14px",
                }}
                type="button"
                className="btn btn-primary shadow-none modalSaveBtn"
                onClick={() => {
                  handleDeleteLinks(link._id);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Linkitem;
