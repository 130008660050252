import React, { useContext, useEffect, useState } from "react";
import CustomModal from "../../Custom/CustomModal";
import ColorPickers from "../../ColorPicker/ColorPicker";
import linkContext from "../../../context/links/linkContext";
import { ColorPicker } from "antd";

const EditSubHeaderStyling = ({
  isEditingSubHeader,
  setIsEditingSubHeader,
  settings,
}) => {
  const context = useContext(linkContext);
  let { updateSubHeaderSetting } = context;
  const [subHeaderSettings, setSubHeaderSettings] = useState({
    fontSize: "",
    fontFamily: "",
    fontColor: "",
  });

  useEffect(() => {
    if (settings?.subHeadingSettings) {
      setSubHeaderSettings({
        fontSize: settings.subHeadingSettings.fontSize || "",
        fontFamily: settings.subHeadingSettings.fontFamily || "",
        fontColor: settings.subHeadingSettings.fontColor || "",
      });
    }
  }, [settings]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubHeaderSettings((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    updateSubHeaderSetting(subHeaderSettings);
    setSubHeaderSettings({
      fontSize: "",
      fontFamily: "",
      fontColor: "",
    });
    setIsEditingSubHeader(false);
  };

  return (
    <div>
      {" "}
      <CustomModal
        show={isEditingSubHeader}
        handleClose={() => setIsEditingSubHeader(false)}
        heading={"Edit Sub Header Settings"}
      >
        <div className="form-group">
          <input
            type="text"
            id="fontSize"
            name="fontSize"
            className="form-control"
            placeholder="Enter font size in pixels"
            value={subHeaderSettings.fontSize}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <select
            required
            className="form-control my-2"
            id="fontFamily"
            name="fontFamily"
            value={subHeaderSettings.fontFamily}
            onChange={handleChange}
          >
            <option value="sans-serif">Sans-Serif</option>
            <option value="Roboto">Roboto</option>
            <option value="serif">Serif</option>
            <option value="arial">Arial</option>
            <option value="Open Sans">Open Sans</option>
            <option value="Futura">Futura</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Calibri">Calibri</option>
            <option value="Verdana">Verdana</option>
            <option value="Palatino">Palatino</option>
          </select>
        </div>

        <div className="input-group">
          <input
            type="text"
            id="fontColor"
            className="form-control my-2"
            name="fontColor"
            placeholder="Enter color"
            value={subHeaderSettings.fontColor}
            onChange={handleChange}
          />
          <ColorPickers />
        </div>

        <div className="modal-footer px-0">
          <button
            style={{
              background: "#4F8B33",
              border: "1px solid #4F8B33",
              fontSize: "14px",
              padding: "5px 10px",
            }}
            className="btn btn-primary px-2 shadow-none modalSaveBtn"
            onClick={handleSaveClick}
          >
            Save Changes
          </button>
          <button
            style={{
              color: "#4F8B33",
              border: "1px solid #4F8B33",
              fontSize: "14px",
              background: "#fff",
              fontWeight: 500,
              padding: "5px 10px",
            }}
            type="button"
            className="btn btn-secondary shadow-none modalCloseBtn"
            onClick={() => setIsEditingSubHeader(false)}
          >
            Close
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default EditSubHeaderStyling;
