import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { MdChevronRight } from "react-icons/md";
import "./ThemeSection.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import Lottie from "lottie-react";
// import data from "./../../Illustration.json";

const ThemeSection = () => {
  const navigate = useNavigate();
  const [speed, setSpeed] = useState(0.5);
  const token = localStorage.getItem("token");

  const handleClick = () => {
    if (token) {
      navigate("/link");
    } else {
      navigate("/login");
    }
  };

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  return (
    <>
      <Box
        className="theneSectionContainer"
        sx={{
          height: "auto",
          width: "100%",
          p: "5% 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "150px",
          overflow: "hidden",
        }}
        ref={ref}
      >
        <motion.div
          className="leftThemeImgBox"
          initial={{ x: -500, opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: -500, opacity: 0 }}
          transition={{ duration: 0.8 }}
        >
          {/* <Box className="leftThemeImgBox"> */}
          <img
            className="themeSecImg"
            src="/HomePage/theme.png"
            alt="Image"
            loading="lazy"
          />
          <img
            className="themeSectionSecondImg"
            style={{ display: "none" }}
            src="/HomePage/Group 2324.png"
            alt="Image"
            loading="lazy"
          />

          {/* <Lottie speed={speed} animationData={data} /> */}
          {/* </Box> */}
        </motion.div>

        <motion.div
          className="rightThemeBox"
          initial={{ y: 500, opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : { y: 500, opacity: 0 }}
          transition={{ duration: 0.8 }}
        >
          {/* <Box className="rightThemeBox"> */}
          <Typography className="themeSHeading" fontSize={"50px"}>
            Make It Yours!{" "}
          </Typography>
          <Typography className="themeSubHeading" fontSize={"50px"}>
            Theme
          </Typography>
          <Typography className="themeSubHeading" fontSize={"50px"}>
            Customization
          </Typography>
          <Button
            onClick={handleClick}
            sx={{ fontSize: "18px", mt: "50px" }}
            className="themeBtn"
            endIcon={<MdChevronRight />}
          >
            Create Your Tree
          </Button>
          {/* </Box> */}
        </motion.div>

        <Box className="rightSmallSThemeBox">
          <Typography fontSize={"50px"} className="themeSubHeading">
            <span className="themeSHeading">Make It Yours!</span> Theme
            Customization
          </Typography>

          <Button
            onClick={handleClick}
            sx={{ fontSize: "18px", mt: "50px" }}
            className="themeBtn"
            endIcon={<MdChevronRight />}
          >
            Create Your Tree
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ThemeSection;
